.list {
  list-style-type: none;
  font-size: 16px;
  color: #5f5d5d;
  margin-top: 7px;
  .header {
    height: 30px;
  }
  .title {
    position: relative;
    top: -8px;
    right: 2px;
    font-size: 16px;
    color: #252525;
    font-weight: 700;
  }
  .paid{
    color: green;
    font-size: 14px;
    float: left;
  }
  .amount {
    margin-right: 44px;
    margin-top: 5px;
    display: block;
  }
  img {
    width: 40px;
  }
}
