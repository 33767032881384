.toggle{
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    span{
        width: 100%;
        height: 2px;
        display: inline-block;
        background: #008b9b;
        border-radius: 5px;
    }
}