.list {
  list-style-type: none;
  font-size: 16px;
  color: #5f5d5d;
  /* margin: 5
px
; */
  padding: -9px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #8080807d;
}
.header {
  height: 30px;
}
.title {
  position: relative;
  top: -6px;
  right: -2px;
  font-size: 14px;
  color: #252524;
  font-weight: 600;
}

.amount {
  margin-right: 43px;
  margin-top: 17px;
  display: block;
  margin-bottom: 11px;
  font-size: 14px;
}
.img {
  width: 40px;
}
