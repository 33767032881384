.items{
    list-style: none;
    padding: 0 10px;
    a{
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-bottom: 11px;
        span{
            color: #000;
            font-size: 14px;
        }
        i{
            color: #008b70;
            margin-left: 10px;
            font-size: 14px;
        }
    }
}