$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-trash: "\e900";
$icon-rightarrow: "\e901";
$icon-aboutus: "\e902";
$icon-card: "\e903";
$icon-help: "\e904";
$icon-inbox: "\e905";
$icon-settings: "\e906";
$icon-transaction: "\e907";
$icon-mobile: "\e908";
$icon-left: "\e909";
$icon-right: "\e90a";