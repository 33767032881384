.row {
  display: flex;
}
.col-6 {
  width: 50%;
}
.col-4 {
  width: 30%;
}
.col-8 {
  width: 70%;
}

.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
