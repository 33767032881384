.danjerBtn{
    width: 100%;
    height: 38px;
    border: none;
    color: #fff;
    background: #fc4878;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0px 1px 1px 1px #7777;
    font-size: 16px;
}