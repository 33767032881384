.myCards {
  padding: 8px 0;
  height: 179px;
  position: relative;
  .item {
    position: absolute;
    width: 300px;
    transition: 0.15s;
    transform: translateX(50%);
    img {
      width: 300px;
    }

    & > i {
      position: fixed;
      top: 42%;
      font-size: 29px;
    }
    .left {
      left: -30px !important;
    }
    .right {
      right: -30px !important;
    }

    & > span {
      position: absolute;
      direction: ltr;
    }

    .number {
      right: 10%;
      top: 38%;
      font-size: 22px;
    }
    .name {
      right: 10%;
      top: 53%;
      font-size: 14px;
    }
    .cvv2 {
      left: 10%;
      top: 68%;
      font-size: 13px;
    }
    .expire {
      left: 10%;
      top: 68%;
      font-size: 13px;
    }
  }
}

.selected {
  right: 0 !important;
}

.outItem {
  right: -100% !important;
}
