    .emptyCard{
        display: block;
        i{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 102px;
            color: #dfe2ec;
        }
        span{
            position: fixed;
            right: 0;
            top: 58%;
            color: #cfd3e1;
            font-size: 17px;
            width: 100%;
            text-align: center;
        }
    }
