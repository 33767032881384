.btnContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 40px;
  button {
    border: none;
    margin: 5px;
    display: inline-block;
    background: #008b70;
    color: #fff;
    font-size: 29px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 1px #7777;
    display: flex;
    justify-content: space-around;
  }
  .delete {
    background: #fc4878;
    font-size: 22px;
    span {
      top: 11px;
      position: relative;
    }
  }
}
