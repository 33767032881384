@import './iconSetVariable';

@font-face {
  font-family: 'icomoon';
  src: url('../assets/iconSet/icomoon.eot?yqi6h7');
  src: url('../assets/iconSet/icomoon.eot?yqi6h7#iefix')
      format('embedded-opentype'),
    url('../assets/iconSet/icomoon.ttf?yqi6h7') format('truetype'),
    url('../assets/iconSet/icomoon.woff?yqi6h7') format('woff'),
    url('../assets/iconSet/icomoon.svg?yqi6h7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-rightarrow {
  &:before {
    content: $icon-rightarrow;
  }
}
.icon-aboutus {
  &:before {
    content: $icon-aboutus;
  }
}

.icon-card {
  &:before {
    content: $icon-card;
  }
}

.icon-help {
  &:before {
    content: $icon-help;
  }
}

.icon-inbox {
  &:before {
    content: $icon-inbox;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-transaction {
  &:before {
    content: $icon-transaction;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}
.icon-left {
  &:before {
    content: $icon-left;
  }
}
.icon-right {
  &:before {
    content: $icon-right;
  }
}
