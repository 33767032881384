.list {
  list-style-type: none;
  font-size: 16px;
  color: #5f5d5d;
  margin-top: -2px;
  margin-bottom: 8;
  .header {
    height: 30px;
  }
  .title {
    position: relative;
    top: -14px;
    right: 5px;
    font-size: 14px;
    color: #252524;
    font-weight: 600;
  }
  .paid {
    color: green;
    font-size: 14px;
    float: left;
  }
  .amount {
    margin-right: 48px;
    margin-top: -7px;
    display: block;
    font-size: 14px;
    margin-bottom: 9px;
  }
  img {
    width: 40px;
  }
}
