.header {
  display: flex !important;
  justify-content: space-between;
  padding: 7px 15px;
  font-size: 14px;
}

.boxshadow {
  box-shadow: -1px 2px 12px 1px #808080b5;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #d1ffd975;
}
