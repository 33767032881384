.spinner {
  position: absolute;
  top: 25%;
  right: 40%;
  margin: 0 auto;
  width: 70px;
  text-align: center;
  direction: ltr;
}

.spinner > div {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.42s;
  animation-delay: -0.42s;
}
.spinner .bounce3 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.spinner .bounce4 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
