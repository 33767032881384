.main{
    .box{
        height: 100vh;
        width: 49%;
        position: fixed;
        top: 0;
        right: -50%;
        background: #fff;
        z-index: 2;
        box-shadow: 0px 0px 3px 0px #7777;
        transition: 0.3s;
    }
    .shadow{
        width: 100%;
        height: 100vh;
        z-index: 1;
        position: fixed;
        background: #7777;
        top: 0;
        right: 0;
        display: none;
    }
 
}

.openBox{
    right: 0 !important;
}

.openShadow{
    display: block !important;
}