.main{
    width: 100%;
    height: 55px;
    background: #fff;
    border-bottom: 2px solid #e9e9f5;
    display: flex;
    padding: 0 15px;
    position: sticky;
    top: 0;
    justify-content: space-between;
    align-items: center;
}