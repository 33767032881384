.dynamicPassBtn {
  button {
    width: 100%;
    height: 36px;
    background: #0087b8;
    border: none;
    color: #fff;
    font-size: 15px;
    border-radius: 4px;
    margin-top: 5px;
    box-shadow: 0px 0px 1px 1px #7777;
  }
}
