.title {
  text-align: center;
  border-bottom: 1px solid #168613;
  color: white;
  padding: 7px;
  background-color: #008b70;
  width: 100%;
  border-radius: 4px;
  height: 38 px;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 7px 15px;
  font-size: 14px;
}

.center {
  // display: inline-block !important;
  // margin: 15px auto 0px auto;
  // color: #4caf50;
  // font-size: 15px;
  // border: 1px solid #008b70;
  // padding: 6px 45px;
  // border-radius: 5px;
  // font-weight: 700;
  display: inline-block !important;
  margin: 15px auto 0 auto;
  color: #4caf50;
  font-size: 13px;
  /* border: 1
px
solid #008b70; */
  padding: 6px 45px;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.boxshadow {
  box-shadow: -1px 2px 12px 1px #808080b5;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #d1ffd975;
}
