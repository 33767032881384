.input {
  margin-bottom: 18px;
  position: relative;
  label {
    display: block;
    color: #7f7f8e;
    position: absolute;
    top: -7px;
    right: 11px;
    font-size: 13px;
    background: #ffff;
    padding: 0 5px;
  }
  input {
    width: 100%;
    margin-top: 5px;
    height: 38px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #e0e0ed;
    border-radius: 4px;
  }

  i {
    position: absolute;
    right: 6px;
    top: 13px;
    color: #008b70;
    font-size: 20px;
  }

}
