.main{
color: #008b9b;
width: 18px;
height: 18px;
border-radius: 50%;
border: 1px solid #008b9b;
text-align: center;
position: relative;
    span{
        position: absolute;
        top: -3px;
        right: 4px;
        font-size: 15px;
    }
}