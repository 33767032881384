.tab{
    ul{
        display: flex;
        justify-content: space-around;
        background: #fff;
        margin: 5px 0;
        padding: 0;
        li{
            list-style-type: none;
            padding: 10px 10px;
            position: relative;
            cursor: pointer;
            transition: 0.3s;
            &::before{
                content: "";
                width: 0;
                height: 2px;
                position: absolute;
                background: #008b70;
                right: 50%;
                bottom: 0;
                transition: 0.3s;
            }
            &::after{
                content: "";
                width: 0;
                height: 2px;
                position: absolute;
                background: #008b70;
                left: 50%;
                bottom: 0;
                transition: 0.3s;
            }
        }
        .active{
            color: #008b70;
            &::before{
                width: 50% !important;
            }
            &::after{
                width: 50% !important;
            }
        }
    }
}