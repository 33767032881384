.backdrop {
  width: 0;
  height: 100vh;
  position: fixed;
  background: #7777;
  z-index: 1;
}
.activeOverlay {
  width: 100% !important;
}

.modalOverlay {
  position: fixed;
  width: 80%;
  background: #ffff;
  z-index: -1;
  top: 30%;
  right: 10.5%;
  border-radius: 20px;
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  .check {
    background: #32bea6;
    color: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    display: inline-block;
    margin-left: 6px;
  }
  .multiple {
    background: #fc4878;
    color: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    display: inline-block;
    margin-left: 6px;
  }
  .text {
    font-weight: 700;
    position: relative;
    top: -2px;
    font-size: 15px;
  }

  main {
    display: none;
    & > span {
      color: #8d94ae;
      margin-right: 28px;
      font-size: 14px;
    }
  }
}

.modalOverlayActive {
  top: calc(50% - 72px);
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 3 !important;
  main {
    display: block !important;
  }
}
