.inquiry {
  width: 100%;
  height: 226px;
  display: block;
  background: #ffff;
  bottom: -415px;
  position: fixed;
  border-radius: 25px 25px 0 0;
  z-index: 2;
  transition: 0.3s;
  .InqueryListData {
    background: #ffff;
    padding: 10px 14px;
  }
  h4 {
    padding: 20px 6px 6px 0;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #7777;
    color: #787777f2;
    font-size: 16px;
  }

  .closeIcon {
    font-size: 16px;
    position: absolute;
    left: 20px;
    top: 5px;
    color: #7777;
    padding: 15px 6px 6px 0;
  }
}
.shadow {
  width: 100%;
  height: 0;
  z-index: 1;
  position: fixed;
  background: #7777;
  top: 0;
  right: 0;
}
.activeInquiry {
  bottom: 0 !important;
}
.activeShadow {
  height: 100vh;
}
