.form {
  text-align: center;
  padding: 25px;
  margin-top: 20px;
  h6 {
    margin: 0;
    text-align: right;
  }

  .formGroup {
    position: relative;
    padding-top: 15px;
    input {
      width: 99%;
      border: 1px solid #7777;
      border-radius: 4px;
      height: 38px;
      text-align: center;
      padding: 0;
      direction: ltr;
      font-size: 16px;
    }
    i {
      position: absolute;
      right: 12px;
      top: 22px;
      color: #008b70;
      font-size: 23px;
    }
  }

  button {
    width: 100%;
    height: 38px;
    margin-top: 5px;
    background: #008b70;
    color: #fff;
    border: navajowhite;
    border-radius: 4px;
    padding: 0;
    font-size: 16px;
  }

  .recommenderlable {
    display: inline-block;
    height: 38px;
    color: #777;
    cursor: pointer;
    text-decoration: none;
    margin-top: 14px;
    margin-left: 5px;
    font-size: 14px;
    color: #0661b6;
    -webkit-tap-highlight-color: transparent;

  }

  .recommenderinput {
    height: 38px;
  }
  .recommenderinput::placeholder{
    font-size: 13px;
  }
}
