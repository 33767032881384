    .cardInfo{
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        .title{
            color: #91919e;
        }
        .value{
            color: #232323;
        }
    }
