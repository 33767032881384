.conection {
  width: 70%;
  text-align: center;
  display: none;
  justify-content: center;
  margin: 0 auto;
  top: 30%;
  background: #ffff;
  position: fixed;
  z-index: 2;
  transition: 0.3s;
  box-shadow: 1px 1px 1px 1px #777;
  
  .conectionListData {
    background: #ffff;
    padding: 10px 14px;
  }

  .closeIcon {
    font-size: 25px;
    position: absolute;
    left: 20px;
    top: 0;
    color: #7777;
    padding: 15px 6px 6px 0;
  }
  .button {
    width: 40%;
  }
}
.shadow {
  width: 100%;
  height: 0;
  z-index: 1;
  position: fixed;
  background: #7777;
  top: 0;
  right: 0;
}
.activeConection {
  display: block !important;
}
.activeShadow {
  height: 100vh;
  visibility: visible !important;
}
.img {
  display: block;
  margin: auto;
  width: 30px;
}
.center {
  display: flex;
  justify-content: center;
}
