.navLogo{
    width: 100%;
    background: #f1f3f9;
    text-align: center;
    padding: 5px;
    height: 83px;
    border-bottom: 1px solid #e9e9f5;
    img{
        height: 70px;
    }
}