.itemsBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 25px 0px;

  .items {
    text-align: center;
    width: 30%;

    .item {
      border: 1px solid #e0e0ed;
      border-radius: 65%;
      width: 80px;
      height: 80px;
      display: inline-block;
      text-align: center;
      padding: 29px 10px;
      position: relative;
    }
    .amount {
      text-align: center;
      position: absolute;
      top: 21px;
      width: 100%;
      left: 0px;
    }
    .currency {
      text-align: center;
      position: absolute;
      top: 41px;
      width: 100%;
      left: 0px;
    }
  }
  .active {
    background-color: #0087b8;
    color: #ffffff;
    border: none;
  }
}
