// .list {
//   list-style-type: none;
//   font-size: 16px;
//   color: #5f5d5d;
//   margin-top: -2px;
//   margin-bottom: 8;
//   position: relative;
//   .title {
//     top: 4px;
//     right: 12px;
//     font-size: 14px;
//     color: #252524;
//     font-weight: 600;
//     position: absolute;
//   }
//   .name {
//     top: 20px;
//     right: 12px;
//     font-size: 14px;
//     color: #252524;
//     font-weight: 600;
//   }
//   .amount {
//     margin-right: 48px;
//     margin-top: -7px;
//     display: block;
//     font-size: 14px;
//     margin-bottom: 9px;
//   }
// }
.title {
  margin-right: 10px;
}
.name {
  margin-right: 10px;
  color: #cd4040;
}
.nameT {
  margin-right: 10px;
}
.amount {
  margin-right: 10px;
  color: green;
  font-weight: 700;
}
.amountt {
  margin-right: 10px;
}
.icon-mobile {
  margin-right: 10px;
}
