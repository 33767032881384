.cart{
    width: 30%;
    height: auto;
    background: #ffff;
    padding-bottom: 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px #7777773b;
    margin-bottom: 30px;
    img{
        width: 50%;
        display: block;
        margin: auto;
        margin-top: 16px;
    }
    h6{
        margin: 0;
        margin-top: 8px;
        display: block;
        text-align: center;
    }
    a{
        text-decoration: none;
        color: #2c394b;
    }
}