.pageHeader {
  width: 100%;
  height: 55px;
  background: #fff;
  border-bottom: 2px solid #e9e9f5;
  display: flex;
  padding: 0 15px;
  position: sticky;
  top: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  a {
    text-decoration: none;
    color: #000000;
    position: relative;
    top: 5px;
  }
  span {
    margin-right: 7px;
  }
}
