@import './styles/iconSet';
@import './styles/object';

@font-face {
  font-family: 'iransanse';
  src: url('./assets/fonts/iransanse.woff');
}

html {
  position: relative;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
body,
button,
input {
  font-family: iransanse;
  -webkit-font-smoothing: iransanse;
  -moz-osx-font-smoothing: iransanse;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-gray {
  background: #ecf0fb;
  width: 100%;
  // height: 100vh;
}
input[type='tel'] {
  direction: ltr;
}

#root {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
